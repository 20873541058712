import React from 'react';

import './styles.scss';
import logo from '../../images/header/realmarked.svg'
import arrowRight from '../../images/header/arrow-right.svg'

function Header(props: any) {
    return (
        <div className="header">
            <div className="header-container">
                <div className="header-content-left">
                    <img src={logo} alt="Realmarked" />
                </div>
                <div className="header-content-right">
                    <div className="header-content-right-btn" onClick={() => props.setShowModalSignup(true)}>
                        <span>Sign up</span>
                        <img src={arrowRight} alt="" />                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
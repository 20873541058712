import React from 'react';

import './styles.scss';
import fastest from '../../images/signup/fastest.svg';
import proprietary from '../../images/signup/proprietary.svg';
import accountable from '../../images/signup/accountable.svg';
import arrowRight from '../../images/signup/arrow-right.svg';

function Signup(props:any) {

    return (
        <div className="signup">
            <div className="signup-container">
                <div className="signup-left">
                    <h1>Sign up now for a<br />
                        <span>free</span> realtime trial
                    </h1>
                </div>

                <div className="signup-right">
                    <ul>
                        <li>
                            <img src={fastest} alt="fastest" />
                            <span>The fastest available market data</span>
                        </li>

                        <li>
                            <img src={proprietary} alt="proprietary" />
                            <span>Proprietary accuracy checks</span>
                        </li>

                        <li>
                            <img src={accountable} alt="accountable" />
                            <span>Accountable data sources</span>
                        </li>
                    </ul>
                    <div className="signup-right-btn" onClick={() => props.setShowModalSignup(true)}>
                        <span>Sign up</span>
                        <img src={arrowRight} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup;
import react, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { Spinner } from 'react-bootstrap';

import './styles.scss';
import { useEffect } from 'react';

import signupSuccess from '../../images/modal-signup/modal-signup-success.svg';

function ModalSignup(props: any) {
    const [form, setForm] = useState({ name: '', email: '', organization: '', phone: '' });
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const mailChimpAction = "https://realmarked.us6.list-manage.com/subscribe/post?u=dee4036cd3a22751e8538509d&amp;id=02a34be64b";

    const inputChanges = (event: any) => {
        const { id, value } = event.target;
        setForm({ ...form, [id]: value })
    }

    const cleanForm = () => {
        setForm({ name: '', email: '', organization: '', phone: '' })
    }

    const handleSubmit = (event: any) => {

        setValidated(true);
        const signupForm = document.querySelector('form')

        if (signupForm && !signupForm.checkValidity() === false) {

            signupForm.submit()
            setLoading(true);

            setTimeout(() => {
                cleanForm();
                setShowSuccessMessage(true);
                setLoading(false)
            }, 1000);
        }
        else {
            event.preventDefault();
        }
    };

    useEffect(() => {
        cleanForm();
        setShowSuccessMessage(false);
        setValidated(false);
    }, [props.showModalSignup])

    const metrolistForm = window.location.pathname === '/metrolist';

    return (
        <>
            <iframe name="hiddenFrame" style={{ display: 'none' }} ></iframe>
            <Modal
                show={props.showModalSignup}
                onHide={() => props.setShowModalSignup(false)}
                backdrop="static"
                keyboard={false}
                className="right"
                size="lg"
            >
                <Modal.Header closeButton>
                    {loading &&
                        <div className="loading">
                            <Spinner animation="border" variant="primary" />
                            <span>Sending your subscription</span>
                        </div>
                    }
                    {!loading && showSuccessMessage &&
                        <div className="success-message">
                            <h1>All good!</h1>
                            <img src={signupSuccess} alt="all good!" />
                            <h2>
                                <span>Thanks for signing up!</span>
                                <br />
                                <span>You'll receive an e-mail about your free trial soon.</span>
                            </h2>
                            <div className="btn-gotit" onClick={() => props.setShowModalSignup(false)}>Got it</div>
                        </div>
                    }
                    {!loading && !showSuccessMessage &&
                        <div className="signup-header">
                            <h1>Sign up</h1>
                            <p>By signing up you'll gain access to a free realtime trial, along with other updates on what's coming down the line with Realmarked.</p>
                        </div>
                    }
                </Modal.Header>
                <Modal.Body>
                    {!loading && !showSuccessMessage &&
                        <Form noValidate method="post" target="hiddenFrame"
                            onSubmit={handleSubmit}
                            validated={validated}
                            action={mailChimpAction}
                        >
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" required value={form.name} name="NAME"
                                    onChange={inputChanges} />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid name.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="email">
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control type="email" required value={form.email} name="EMAIL"
                                    onChange={inputChanges} />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid email.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="organization">
                                <Form.Label>{metrolistForm ? 'Metrolist ID' : 'Organization'}</Form.Label>
                                <Form.Control type="text" value={form.organization} name="COMPANY"
                                    onChange={inputChanges} />
                            </Form.Group>

                            <Form.Group controlId="phone">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="text" value={form.phone} name="PHONE"
                                    onChange={inputChanges} />
                            </Form.Group>

                            <Form.Group controlId="button">
                                <input type="submit" className="btn-create" value="Create account" />
                            </Form.Group>
                        </Form>
                    }

                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalSignup;
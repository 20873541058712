import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './App.scss';

import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Metrolist from './components/Metrolist/Metrolist';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Routes>
          <Route path="/metrolist" element={<Metrolist />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
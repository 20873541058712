import React, { useState } from 'react';

import './styles.scss';

import logo from '../../images/header/realmarked.svg'
import arrowRightHeader from '../../images/header/arrow-right.svg'
import arrowRightSignup from '../../images/signup/arrow-right.svg'
import streetwire from '../../images/partners/streetwire.png';
import ModalSignup from '../ModalSignup/ModalSignup';

function Metrolist() {

    const [showModalSignup, setShowModalSignup] = useState(false);
    const products = ['Hyper focused market location', 'Drill down by bedrooms', 'Track Market Change', '6 month free trial'];

    const platformProducts = products.map((item) =>
        <div className="metrolist-platform-product" key={item}>
            <h2>{item}</h2>
        </div>
    );

    return (
        <>
            <div className="metrolist-header">
                <div className="metrolist-header-container">
                    <div className="metrolist-header-content-left">
                        <img src={logo} alt="Realmarked" />
                    </div>
                    <div className="metrolist-header-content-right">
                        <div className="metrolist-header-content-right-btn" onClick={() => setShowModalSignup(true)}>
                            <span>Sign up</span>
                            <img src={arrowRightHeader} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="metrolist-intro">
                <div className="metrolist-intro-container">
                    <div className="metrolist-intro-description">
                        <h1>MetroList MLS Custom<br />Property Index</h1>
                        <p>Track the single-family housing market in real time</p>
                    </div>
                </div>
            </div>

            <div className="metrolist-platform">
                <div className="metrolist-platform-container">
                    <div className="metrolist-platform-title">
                        <h1>How it works</h1>
                    </div>

                    <div className="metrolist-platform-products">
                        {platformProducts}
                    </div>
                </div>
            </div>

            <div className="metrolist-signup">
                <div className="metrolist-signup-container">
                    <div className="metrolist-signup-center">
                        <h1>Sign up for a<br />
                            <span>free</span> 6 month trial
                        </h1>

                        <div className="metrolist-signup-btn" onClick={() => setShowModalSignup(true)}>
                            <span>Sign up</span>
                            <img src={arrowRightSignup} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="metrolist-partners">
                <div className="metrolist-partners-container">
                    <h1>Powered By</h1>
                    <img src={streetwire} alt="streetwire" />
                </div>
            </div>

            <ModalSignup showModalSignup={showModalSignup} setShowModalSignup={setShowModalSignup} />
        </>
    )
}

export default Metrolist;
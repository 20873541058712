import React from 'react'

import './styles.scss';

function Platform() {

    const products = [
        {
            title: 'BESPOKE LOCATIONS',
            detail: 'Subscribe to the States, Cities and Regions that you care about'
        },
        {
            title: 'MARKET ANALYSIS',
            detail: 'Generate market charts and access a suite of analytics tools'
        },
        {
            title: 'TRACK MARKET VALUE CHANGE',
            detail: "Exclusive platform access to StreetWire's Home Price Index and Benchmark Series"
        },
        {
            title: 'OFFLINE ACESSS',
            detail: 'Use the platform to produce and enhance market price/risck reports and due diligence'
        }
    ];

    const platformProducts = products.map((item) =>
        <div className="platform-product" key={item.title}>
            <h2>{item.title}</h2>
            <p>{item.detail}</p>
        </div>
    );

    return (
        <div className="platform">
            <div className="platform-container">
                <div className="platform-title">
                    <h1>PLATFORM ACCESS<br />BREAKDOWN</h1>
                </div>

                <div className="platform-products">
                    {platformProducts}
                </div>
            </div>
        </div>
    )
}

export default Platform;
import React, { useState } from 'react';
import ClosePrice from '../ClosePrice/ClosePrice';
import Header from '../Header/Header';
import Intro from '../Intro/Intro';
import ModalSignup from '../ModalSignup/ModalSignup';
import Partners from '../Partners/Partners';
import Platform from '../Platform/Platform';
import Signup from '../Signup/Signup';

import './styles.scss';

function Home() {
    const [showModalSignup, setShowModalSignup] = useState(false);
    return (
        <>
            <Header showModalSignup={showModalSignup} setShowModalSignup={setShowModalSignup}/>
            <Intro />
            <ClosePrice />
            <Platform />
            <Partners />
            <Signup showModalSignup={showModalSignup} setShowModalSignup={setShowModalSignup} />
            <ModalSignup showModalSignup={showModalSignup} setShowModalSignup={setShowModalSignup} />
        </>
    )
}

export default Home;
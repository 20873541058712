import React from 'react';

import './styles.scss';

function Intro() {
    return (
        <div className="intro">
            <div className="intro-container">
                <div className="intro-description">
                    <h1>Understand real estate markets first</h1>
                    <p>Realmarked is a data platform for professionals who need to understand and manage market price risk in real time</p>
                </div>
            </div>
        </div>)
}

export default Intro;
import React from 'react';

import './styles.scss';
import logo from '../../images/footer/realmarked.svg'

function Footer() {

    const year = new Date().getFullYear()

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="logo">
                    <img src={logo} alt="realmarked" />
                    <span>Realmarked</span>
                </div>
                <div className="copyright">
                    <span>© Copyright {year} Realmarked - All Rights Reserved</span>
                </div>  
            </div>
        </footer>
    )
}

export default Footer;
import React from 'react';

import './styles.scss';
import streetwire from '../../images/partners/streetwire.svg';

function Partners() {
    return (
        <div className="partners">
            <div className="partners-container">
                <h1>Some of our partners</h1>
                <ul>
                    <li>
                        <img src={streetwire} alt="streetwire" />
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Partners;
import React from 'react'

import './styles.scss';

import apiImage from '../../images/platform/api.svg';
import uiImage from '../../images/platform/ui.svg';

function ClosePrice() {
    return (
        <div className="close-price">
            <div className="close-price-container">
                <div className="close-price-left">
                    <h1>
                        Realmarked <br />
                        introduces the<br />
                        <span>Close Price Now</span>
                    </h1>
                    <p>Sync your knowledge industries database in real time with our exclusively faster data sets</p>
                </div>
                <div className="close-price-right">
                    <img src={apiImage} alt="" />
                    <p>An API feed which is directly associated with close price</p>
                    <img src={uiImage} alt="" />
                    <p>Access to the Realmarked Platform so that users can download close price data and use the median price chart tool,  Index and Index Analytics tool.</p>
                </div>
            </div>
        </div>
    )
}

export default ClosePrice;